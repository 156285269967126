import React, { useState } from "react";
import { Modal, Form, Button } from "rsuite";
import "./index.css";
import pic from "../../assets/images/NewlyLaunchedPPL.png";
const NewlyLaunchedModal = ({ modalOpen, handleClose }) => {
  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        style={{ display: "flex" }}
        className="ppModal customBackdrop"
      >
        <Modal.Header>
          <Modal.Title>
            <h3 style={{ fontWeight: "700", color: "#1579be" }}>
              Newly Launched!
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="premium-private-label">
            <strong> Premium Private Labels </strong>
            <br />
            Cutting-edge solution designed to optimize production and ensure
            superior casting results with{" "}
            <strong>guaranteed consistency in material quality.</strong>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              style={{
                width: "100%",
              }}
              src={pic}
              alt=""
            />
          </div>

          <Form.Group style={{ float: "right" }}>
            <a href="https://nowpurchase.com/marketplace?premium-private-labels">
              <Button
                appearance="primary"
                style={{
                  backgroundColor: "#1579BE",
                  fontSize: "16px",
                  fontWeight: 700,
                  padding: "10px 15px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 36 32"
                  fill="none"
                >
                  <path
                    d="M18.5444 16.4806L22.6663 27.8051L18.9075 29.1732L14.7856 17.8487L9.22266 22.0564L11.2103 2.17773L25.5107 16.128L18.5444 16.4806Z"
                    fill="white"
                  />
                </svg>
                Know More
              </Button>
            </a>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewlyLaunchedModal;
