import React from "react";
import "./index.css";
const Strip = () => {
  return (
    <>
      <div className="stripheader">
        <p className="stripP">
          <img src="https://a.slack-edge.com/production-standard-emoji-assets/14.0/google-large/1f389@2x.png" />
          Newly Launched: <strong> Premium Private Labels </strong>
          <a href="https://nowpurchase.com/marketplace?premium-private-labels">
            <u>View Details </u>
          </a>
          <img src="https://a.slack-edge.com/production-standard-emoji-assets/14.0/google-large/1f389@2x.png" />
        </p>
      </div>
    </>
  );
};

export default Strip;
