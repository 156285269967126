import React, { useEffect, useRef, useState } from "react";
import "./index.css";import CountUp from "react-countup";
import recyclePic from "../../../../assets/images/csr/RecyclePic.png";
import recycleLogo from "../../../../assets/images/csr/RecycleLogo.png";
import Container from "../../../../Components/General/Container";
import CircularFrameMobile from "../../../../assets/images/csr/SustainableCircularEconomy/CircularFrame.png";
import RM from "../../../../assets/images/csr/SustainableCircularEconomy/Rm.png";
import RW from "../../../../assets/images/csr/SustainableCircularEconomy/Residual Waste.png";
import Design from "../../../../assets/images/csr/SustainableCircularEconomy/Design.png";
import Reuse from "../../../../assets/images/csr/SustainableCircularEconomy/Reuse.png";
import Manufactur from "../../../../assets/images/csr/SustainableCircularEconomy/Manufacture.png";
import Maintain from "../../../../assets/images/csr/SustainableCircularEconomy/maintain.png";
import Use from "../../../../assets/images/csr/SustainableCircularEconomy/use.png";
import { countCSR } from "../../../constants";

function GreenNarrativeOfScrapHero() {
  const aosRef = useRef(null);
const aosRef1 = useRef(null);
   useEffect(() => {
     const observer = new IntersectionObserver(
       (entries) => {
         entries.forEach((entry) => {
           if (entry.isIntersecting) {
             entry.target.classList.add("ltr");
             // entry.target.classList.remove("ltr-out");
           } else {
             // entry.target.classList.add("ltr-out");
             // entry.target.classList.remove("ltr");
           }
         });
       },
       {
         threshold: 0.3,
         // rootMargin: "-10px",
       }
     );

     if (aosRef.current) {
       observer.observe(aosRef.current);
     }

     // Clean up the observer on component unmount
     return () => {
       if (aosRef.current) {
         observer.unobserve(aosRef.current);
       }
     };
   }, []);
 useEffect(() => {
   const observer = new IntersectionObserver(
     (entries) => {
       entries.forEach((entry) => {
         if (entry.isIntersecting) {
           entry.target.classList.add("aosAnim1");
           // entry.target.classList.remove("ltr-out");
         } else {
           // entry.target.classList.add("ltr-out");
           // entry.target.classList.remove("ltr");
         }
       });
     },
     {
       threshold: 0.3,
       // rootMargin: "-10px",
     }
   );

   if (aosRef1.current) {
     observer.observe(aosRef1.current);
   }

   // Clean up the observer on component unmount
   return () => {
     if (aosRef1.current) {
       observer.unobserve(aosRef1.current);
     }
   };
 }, []);
  return (
    <>
      <div
        ref={aosRef}
        className="marketbnr marketbnrCSR serviceSec animated-section section pb-0"
        id="sec31"
      >
        <div className="overflowSection">
          <div class="container">
            <div ref={aosRef} className="row bannerContent aosAnim ">
              <div className="col-md-7 leftBannerCont">
                <div className="bannerLeft">
                  <h2 className="titleText titleTextSR">
                    Scrap:
                    <span className="skyText"> Rethinking </span>Resources,{" "}
                    <span className="skyText">Reshaping </span>
                    Sustainability
                  </h2>
                </div>
                <div className="keyWrap keyWrapCSR">
                  {countCSR.map(({ number, text, title }) => (
                    <div className="eachkey">
                      <h4>
                        <span className="counter">
                          <CountUp end={number} duration={2} />
                        </span>
                        {text}
                      </h4>
                      <h6>{title}</h6>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-5 rightBannerImg">
                <div className="bannerRight">
                  <div className="bannerPoly bannerPolyCSR">
                    <img src={recyclePic} alt="" />

                    <a
                      href="https://www.youtube.com/embed/O8rqmHvFmQY"
                      className="bnrPlay"
                      data-fancybox=""
                    >
                      <img loading="lazy" src={recycleLogo} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section
            className="keyNumber animated-section triangleTopLeftBg triangleTopLeftBgCSR"
            id=""
          >
            <Container>
              <div className="row">
                <div ref={aosRef} className="col-lg-12 aosAnim">
                  <div className="sechead keyHead aosAnim ">
                    <h3>
                      <span>Sustainable Circular Economy</span>
                    </h3>
                    <p>
                      We at NowPurchase are committed to prioritizing
                      sustainability and environmental consciousness. Central to
                      our mission is advocating for scrap materials as
                      alternatives to conventional raw material resources in
                      manufacturing processes.
                    </p>
                  </div>
                </div>

                <div ref={aosRef1} class="circularFrame aosAnim">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1109"
                    height="532"
                    viewBox="0 0 1109 532"
                    fill="none"
                  >
                    <path
                      d="M35.6484 269.564C35.6484 271.035 35.6484 272.461 35.6937 273.932L79.8413 240.578L122.45 272.778C122.45 271.737 122.405 270.651 122.405 269.564C122.405 268.139 122.405 266.713 122.473 265.288C124.713 183.079 192.326 116.892 275.077 116.892C281.685 116.892 288.157 117.322 294.515 118.137L282.364 146.128L321.397 124.088L369.143 97.0923L356.403 44.3686L345.723 0.220947L329.974 36.4939C312.346 32.3304 293.949 30.1354 275.077 30.1354C144.105 30.1354 37.3456 135.854 35.6711 266.442C35.6711 267.483 35.6711 268.524 35.6711 269.564H35.6484Z"
                      fill="#2BA24C"
                    />
                    <line
                      class="line"
                      x1="15%"
                      y1="20%"
                      x2="5%"
                      y2="20%"
                      stroke="#C3D3DD"
                      stroke-width="0.2%"
                    />
                    <circle
                      class="circle circle1"
                      cx="50%"
                      cy="50%"
                      r="1%"
                      stroke="#AAAAAA"
                      stroke-width="0.3%"
                      fill="#FFFFFF"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M0.307772 300.655H37.6669C52.941 418.073 153.569 509.016 275.059 509.016C289.655 509.016 303.978 507.704 317.849 505.192C320.157 504.762 322.42 504.309 324.683 503.834L281.689 472.607L300.946 420.042C298.842 420.404 296.737 420.743 294.61 420.992C288.206 421.807 281.69 422.259 275.059 422.259C201.563 422.259 139.969 370.011 125.578 300.678H159.339L122.432 272.8L79.8233 240.6L35.6757 273.954L0.285156 300.678L0.307772 300.655Z"
                      fill="#55B570"
                    />
                    <circle
                      class="circle circle2"
                      cx="50%"
                      cy="50%"
                      r="1%"
                      stroke="#AAAAAA"
                      stroke-width="0.3%"
                      fill="#FFFFFF"
                    />
                    <svg
                      className="curveLine"
                      width="31"
                      height="109"
                      viewBox="0 0 31 159"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      x="12.75%"
                      y="80.75%"
                    >
                      <path
                        d="M28.7101 2.17041V133.803C28.7101 140.765 24.2562 146.408 18.7623 146.408H2.37109"
                        stroke="#C3D3DD"
                        stroke-width="2.39423"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                    </svg>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M321.406 124.11C323.714 124.834 325.977 125.626 328.24 126.486C348.424 133.976 366.617 145.63 381.756 160.451L456.089 234.785L490.937 269.61L518.136 242.433L537.076 223.471L552.259 208.31L517.095 173.168L443.033 99.1288C420.405 76.7721 393.364 58.9411 363.359 47.016C361.051 46.0883 358.743 45.2058 356.412 44.3685L369.152 97.0923L321.406 124.088V124.11Z"
                      fill="#55B570"
                    />
                    <circle
                      class="circle3"
                      cx="50%"
                      cy="50%"
                      r="1%"
                      stroke="#AAAAAA"
                      stroke-width="0.3%"
                      fill="#FFFFFF"
                    />{" "}
                    <svg
                      className="curveLine"
                      xmlns="http://www.w3.org/2000/svg"
                      width="170"
                      height="150"
                      viewBox="0 0 170 150"
                      fill="none"
                      x="25%"
                      y="1%"
                    >
                      <path
                        d="M167.915 145.119V23.7294C167.915 11.7968 159.597 2.13574 149.322 2.13574H2.35547"
                        stroke="#C3D3DD"
                        stroke-width="2.39423"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                    </svg>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M281.717 472.607L324.711 503.834L362.387 531.169L351.774 496.412C386.44 484.668 417.554 465.185 443.056 440.023L443.101 440.068L572.761 310.408L578.69 304.48L525.74 297.103L518.137 242.411L512.185 248.339L490.915 269.61L381.756 378.768L381.711 378.723C366.12 393.952 347.316 405.877 326.43 413.344L315.976 378.994L300.928 420.019L281.672 472.585L281.717 472.607Z"
                      fill="#80C794"
                    />
                    <line
                      class="line"
                      x1="40%"
                      y1="72%"
                      x2="40%"
                      y2="92%"
                      stroke="#C3D3DD"
                      stroke-width="0.2%"
                    />
                    <circle
                      class="circle4"
                      cx="50%"
                      cy="50%"
                      r="1%"
                      stroke="#AAAAAA"
                      stroke-width="0.3%"
                      fill="#FFFFFF"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M512 198.376L518.155 242.411L525.758 297.103L578.708 304.48L598.417 284.771L613.601 269.564L722.759 160.406C738.486 145.041 757.539 133.003 778.719 125.536C781.072 124.699 783.47 123.907 785.892 123.183L739.662 97.0696L752.809 42.6714C750.524 43.4407 748.238 44.2554 745.976 45.1152C714.025 57.0177 685.309 75.5728 661.459 99.0835L588.348 172.218L552.301 208.265L537.117 223.425L512.023 198.331L512 198.376Z"
                      fill="#2BA24C"
                    />{" "}
                    <circle
                      class="circle5"
                      cx="50%"
                      cy="50%"
                      r="1%"
                      stroke="#AAAAAA"
                      stroke-width="0.3%"
                      fill="#FFFFFF"
                    />
                    <svg
                      className="curveLine"
                      width="171"
                      height="155"
                      viewBox="0 0 171 155"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      x="60%"
                      y="0%"
                    >
                      <path
                        d="M2.22266 152.644V24.866C2.22266 12.3052 10.6042 2.13574 20.9564 2.13574H169.037"
                        stroke="#C3D3DD"
                        stroke-width="2.39423"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                    </svg>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M739.633 97.0922L785.862 123.205L826.412 146.128L814.034 117.661C819.08 117.163 824.194 116.892 829.376 116.892C913.395 116.892 981.777 185.116 982.049 269.089V269.564C982.049 271.782 982.003 273.954 981.89 276.127L1028.96 240.578L1068.81 270.696V269.564C1068.81 267.867 1068.81 266.147 1068.76 264.45C1066.02 134.791 959.715 30.1354 829.399 30.1354C811.93 30.1354 794.891 32.0361 778.44 35.5888L763.076 0.198242L752.78 42.694L739.633 97.0922Z"
                      fill="#55B570"
                    />
                    <line
                      class="line"
                      x1="85%"
                      y1="20%"
                      x2="95%"
                      y2="20%"
                      stroke="#C3D3DD"
                      stroke-width="0.2%"
                    />
                    <circle
                      class="circle6"
                      cx="50%"
                      cy="50%"
                      r="1%"
                      stroke="#AAAAAA"
                      stroke-width="0.3%"
                      fill="#FFFFFF"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M783.152 504.535C785.347 504.965 787.565 505.35 789.76 505.712C802.635 507.885 815.873 509.016 829.382 509.016C950.873 509.016 1051.52 418.073 1066.8 300.655H1108.48L1068.81 270.696L1028.96 240.578L981.896 276.126L949.424 300.655H978.864C964.472 369.988 902.901 422.237 829.382 422.237C824.245 422.237 819.154 421.965 814.176 421.467C812.139 421.286 810.103 421.037 808.111 420.766L827.096 472.607L783.152 504.535Z"
                      fill="#2BA24C"
                    />{" "}
                    <circle
                      class="circle7"
                      cx="50%"
                      cy="50%"
                      r="1%"
                      stroke="#AAAAAA"
                      stroke-width="0.3%"
                      fill="#FFFFFF"
                    />
                    <svg
                      className="curveLine"
                      xmlns="http://www.w3.org/2000/svg"
                      width="31"
                      height="100"
                      viewBox="0 0 31 143"
                      fill="none"
                      x="84.5%"
                      y="81%"
                    >
                      <path
                        d="M2.42578 2.44177V128.351C2.42578 135.01 6.87972 140.408 12.3736 140.408H28.7648"
                        stroke="#C3D3DD"
                        stroke-width="2.39423"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                    </svg>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M552.281 330.932L588.328 366.979L661.44 440.068C687.915 466.181 720.431 486.162 756.659 497.77L746.431 531.214L783.157 504.581L827.101 472.653L808.116 420.811L792.796 379.062L781.935 414.747C759.465 407.393 739.281 394.947 722.74 378.791L649.922 305.973L613.581 269.655L598.398 284.861L624.488 310.952L578.711 304.57L552.304 330.977L552.281 330.932Z"
                      fill="#55B570"
                    />{" "}
                    <line
                      class="line"
                      x1="60%"
                      y1="72%"
                      x2="60%"
                      y2="92%"
                      stroke="#C3D3DD"
                      stroke-width="0.2%"
                    />
                    <circle
                      class="circle8"
                      cx="50%"
                      cy="50%"
                      r="1%"
                      stroke="#AAAAAA"
                      stroke-width="0.3%"
                      fill="#FFFFFF"
                    />
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M512 198.376L518.155 242.433L525.758 297.126L578.708 304.502L624.485 310.884L598.417 284.793L613.601 269.587L652.136 231.051L588.302 172.263L552.278 208.31L537.072 223.471L512 198.376Z"
                      fill="#2BA24C"
                    />
                  </svg>

                  <div className="circularFramePics">
                    <div className="circularFramePic circularFramePic1">
                      <img src={RM} alt="" />
                      <p>Raw Materials</p>
                    </div>

                    <div className="circularFramePic circularFramePic2">
                      <img src={Design} alt="" />
                      <p>Design</p>
                    </div>

                    <div className="circularFramePic circularFramePic3">
                      <img src={Reuse} alt="" />
                      <p>Reuse</p>
                    </div>

                    <div className="circularFramePic circularFramePic4">
                      <img src={Maintain} alt="" />
                      <p>Maintain</p>
                    </div>
                    <div className="circularFramePic circularFramePic5">
                      <img src={RW} alt="" />
                      <p>Residual Waste</p>
                    </div>
                    <div className="circularFramePic circularFramePic6">
                      <img src={Use} alt="" />
                      <p>Use</p>
                    </div>
                    <div className="circularFramePic circularFramePic7">
                      <img src={recycleLogo} alt="" />
                      <p>Recycle</p>
                    </div>
                    <div className="circularFramePic circularFramePic8">
                      <img src={Manufactur} alt="" />
                      <p>Manufacture</p>
                    </div>
                  </div>
                </div>
                <div className="circularFrameMobile">
                  <img src={CircularFrameMobile} alt="" />
                </div>
              </div>
            </Container>
          </section>
        </div>
      </div>
    </>
  );
}

export default GreenNarrativeOfScrapHero;
