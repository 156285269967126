import React, { useEffect, useState } from "react";

import TechnologyDriven from "./TechnologyDriven";
import ProcurementSection from "./ProcurementSection";
import OfferingsSection from "./OfferingsSection";
import CultureSection from "./CultureSection";
import CustomerCards from "../General/Cards/CustomerCards";
import MediaCards from "./MediaCards";
import InvestorSection from "./InvestorSection";
import AccoladesAssociations from "./AccoladesAssociations";
import NewlyLaunchedModal from "../Newly_Launched_Model";

function Home() {
  const [modalOpen, setModalOpen] = useState(false);

  const onClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const hasShownModal = sessionStorage.getItem("hasShownModal");
    if (!hasShownModal) {
      const timer = setTimeout(() => {
        setModalOpen(true);
        sessionStorage.setItem("hasShownModal", "true");
      }, 5000); // Delay of 5 seconds (5000 milliseconds)

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <NewlyLaunchedModal modalOpen={modalOpen} handleClose={onClose} />
      <TechnologyDriven />
      <ProcurementSection />
      <OfferingsSection />
      <CultureSection />
      <CustomerCards />
      <MediaCards />
      <AccoladesAssociations />
      <InvestorSection />
    </>
  );
}

export default Home;
